// Import Alpine, Alpine Components and Alpine Plugins
import Alpine from 'alpinejs'
import ui from '@alpinejs/ui'
import collapse from '@alpinejs/collapse'
import focus from '@alpinejs/focus'
import intersect from '@alpinejs/intersect'

// Import Component specific JS
import mobileMenu from './components/mobileMenu.js'

(async () => {
  // Make alpine easier to use from the DevTools
  window.Alpine = Alpine

  // AlpineJS Components: Headless UI
  // https://alpinejs.dev/components
  Alpine.plugin(ui)

  // AlpineJS PLugin: Collapse for smooth animations
  Alpine.plugin(collapse)
  Alpine.plugin(focus)

  // AlpineJS Plugin: Intersect for carousel
  Alpine.plugin(intersect)

  // Alpine Data
  // https://alpinejs.dev/globals/alpine-data
  Alpine.data('mobileMenu', mobileMenu)

  const hasCarousel = document.querySelector(["[data-carousel]"]);
  if (hasCarousel) {
    await import("./components/carousel").then(( { default: carousel } ) => {
      Alpine.data("carousel", carousel);
    })
  }

  Alpine.start();

  const HelpscoutBeaconButton = document.querySelectorAll('[data-link="helpscout"]');

  if(HelpscoutBeaconButton) {
    HelpscoutBeaconButton.forEach((button) => {
      button.addEventListener("click", () => {
        window.Beacon("toggle");
      });
    });
  }
})();
